import styled from '@emotion/styled';
import { breakpoints, colors, spacing } from '../../utils/styleguide';

const Paper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing[4] - 0.5}px ${spacing[3] - 0.5}px;
  background-color: ${colors.white};
  border: 0.5px solid ${colors.grey};
  box-shadow: 0.5px 2px 4px rgba(10, 29, 82, 0.1);
  border-radius: 8px;
  ${breakpoints.tablet} {
    padding: ${spacing[6] - 0.5}px;
  }
`;

export default Paper;
