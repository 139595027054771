import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { colors, spacing } from '../../../utils/styleguide';
import Typography from '../text/Typography';

const ButtonBase = styled.button`
  background-color: ${colors.white};
  -webkit-appearance: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  :focus,
  :hover {
    background-color: ${colors.lightGrey};
  }
`;

const Button = styled(ButtonBase)`
  padding: ${spacing[1]}px ${spacing[3]}px;
  display: inline-flex;
  border: 2px solid ${colors.lightGrey};
`;

const SocialLogo = styled.span`
  display: inline-flex;
  padding-right: ${spacing[3]}px;
`;

const Label = styled(Typography)``;

type Props = {
  label?: string;
  logo?: ReactNode;
  onClick?: () => void;
  ariaLabel?: string;
  className?: string;
};

function SocialLoginButton({ label, logo, onClick, ariaLabel, className }: Props) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      onClick={handleClick}
      // Unfocus the button after use
      onMouseDown={(e) => e.preventDefault()}
      aria-label={ariaLabel}
      type="button"
      className={className}
    >
      <SocialLogo>{logo}</SocialLogo>
      <Label variant="buttonSmall" tag="span">
        {label}
      </Label>
    </Button>
  );
}

export default SocialLoginButton;
